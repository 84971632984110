$exampleHeight: 100px;

// FixedButtonIconOnly
$fixedButtonIconOnlySize: 40px;

// Avatar
$avatarSize: 100px;

// ChatLaunchButton
$chatLaunchButtonWidth: 320px;
$chatLaunchButtonHeight: 96px;
$chatLaunchButtonGap: 12px;

// fixedButton
$fixedButtonHeight: 40px;
$fixedButtonGap: 4px;
$fixedButtonTextMaxWidth: 180px;
$fixedButtonMaxWidth: 200px;

// Header
$headerHeight: 96px;

// Toast
$toastHeight: 40px;

// Scrollbar
$scrollbarWidth: 4px;
$scrollbarMinHeight: 180px;

// CommentBalloonOperator && CommentBalloonUser
$commentMaxWidth: 241px;

// LiquidButtons
$liquidButtonGap: 4px;
$liquidButtonWidth: 102px;
$liquidButtonHeight: 40px;
$liquidButtonIconOnlySize: 40px;

// QuitConfirm
$quitConfirmButtonWidth: 120px;
$quitConfirmGap: 8px;

// OperatorComments & UserComments
$commentsGap: 4px;
$timesGap: 12px;

// ChoiceButton
$choiceButtonGap: 4px;
$choiceButtonWidth: 240px;

// TextField
$textFieldMaxHeight: 88px;

// ChatMessages
$chatMessagesHeight: 554px;
$chatMessagesMinHeight: 200px;
$chatMessagesGap: 4px;

// TextInput
$textInputMinHeight: 72px;
$textInputGap: 8px;

// ChatContainer
$chatContainerWidth: 360px;

// ContainButtonReset
$buttonReset: 30px;
