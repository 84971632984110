@use '../../../styles/lengths';
@use '../../../styles/colors';
@use '../../../styles/radius';
@use '../../../styles/shadows';
@use '../../../styles/zIndex';

.container {
  position: relative;
  z-index: zIndex.$zIndex1;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: lengths.$headerHeight;
  background: colors.$white;
  border-radius: radius.$headerRadius radius.$headerRadius 0 0;
  box-shadow: shadows.$headerShadow;

  .avatar {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .spacing {
    width: lengths.$fixedButtonIconOnlySize;
  }

  .button {
    margin: 8px 8px 0 0;

    svg {
      path {
        fill: colors.$darkBlue100;
      }
    }
  }
}
