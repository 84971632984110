@use '../../../styles/colors';
@use '../../../styles/radius';
@use '../../../styles/lengths';

.container {
  height: 100%;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  list-style: none;

  &::-webkit-scrollbar {
    width: lengths.$scrollbarWidth;
    min-height: lengths.$scrollbarMinHeight;
  }

  &::-webkit-scrollbar-thumb {
    background: colors.$gray50WithOpacity;
    border-radius: radius.$small;
  }
}
