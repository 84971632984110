// black
$black: #1e1b2f;

// blue
$royalBlue: #3856b5;
$royalBlue20: #b7c9fd;
$royalBlue100: #5076f1;
$darkBlue100: #374677;

// red
$red: #ac253c;
$red100: #e43555;

// purple
$purple: #6145ac;
$purple100: #885de7;

// gradation
$gradationBackground100: radial-gradient(168% 100% at 50% 0%, #6d7abc 0%, #293250 100%);
$gradationPrimary100: linear-gradient(180deg, #95abf7 0%, #6385f2 100%);
$gradationPrimary110: linear-gradient(180deg, #6a7fbb 0%, #4665b7 100%);

// white
$white: #fff;

// gray
$gray10: #e8e8ea;
$gray20: #d2d1d5;
$gray40: #a5a4ac;
$gray50: #8e8d97;
$gray50WithOpacity: rgba(142 141 151 / 50%);
$gray80: #4b4959;

// transparent
$transparent: transparent;

// QuitConfirm
$quitConfirmBackground: rgba(0 0 0 / 80%);
