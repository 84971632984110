$none: 0;
$small: 2px;
$base: 4px;
$medium: 6px;
$large: 8px;
$extraLarge: 12px;
$extraExtraLarge: 16px;
$textFieldRadius: 20px;
$headerRadius: 32px;
$liquidButtonRadius: 32px;
$fixedButtonFillRadius: 32px;
$chatLaunchButtonRadius: 48px;
$circle: 9999px;
