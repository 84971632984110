@use '../../../styles/colors';
@use '../../../styles/radius';
@use '../../../styles/typography';
@use '../../../styles/shadows';
@use '../../../styles/lengths';

.container {
  display: flex;
  gap: lengths.$chatLaunchButtonGap;
  align-items: center;
  padding: 8px;
  animation: move-up-down 3s ease infinite;

  &:hover {
    cursor: pointer;
  }
}

@keyframes move-up-down {
  0%,
  100% {
    transform: translateY(-25px);
  }

  50% {
    transform: translateY(0);
  }
}
