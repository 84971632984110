@use '../../../styles/colors';
@use '../../../styles/typography';
@use '../../../styles/lengths';

.container {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  gap: lengths.$quitConfirmGap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: colors.$quitConfirmBackground;

  span {
    font-family: typography.$fontFamily;
    font-size: typography.$fontSize14;
    font-weight: typography.$fontWeight400;
    line-height: typography.$lineHeight24;
    color: colors.$white;
  }

  .buttons {
    display: flex;
    gap: lengths.$quitConfirmGap;

    .fixedButton {
      width: lengths.$quitConfirmButtonWidth;
    }
  }
}
