@use '../../../styles/colors';
@use '../../../styles/lengths';
@use '../../../styles/typography';
@use '../../../styles/radius';

.userChoices {
  padding-top: 12px;
  padding-bottom: 12px;
}

.userTextMessage {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.fadeIn {
  opacity: 0;
  animation-name: fade-in-anime;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes fade-in-anime {
  from {
    opacity: 0;
    transform: translateY(0);
  }

  to {
    opacity: 1;
    transform: translateY(8px);
  }
}
