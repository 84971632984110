@use '../../../styles/zIndex';

.container {
  position: fixed;
  bottom: 0;
}

.svg {
  position: absolute;
  top: -10px;
  right: 15px;
  z-index: zIndex.$zIndex100;
}
