@use '../../../styles/colors';
@use '../../../styles/radius';
@use '../../../styles/lengths';
@use '../../../styles/typography';

.container {
  display: flex;
  gap: lengths.$fixedButtonGap;
  align-items: center;
  width: 100%;
  height: lengths.$fixedButtonHeight;
  color: colors.$white;
  background: colors.$gradationPrimary100;
  border: none;
  border-radius: radius.$fixedButtonFillRadius;

  &.centerElement {
    justify-content: center;
  }

  &.leftElement {
    justify-content: flex-start;
    padding: 0 16px;
  }

  span {
    max-width: lengths.$fixedButtonTextMaxWidth;
    overflow: hidden;
    font-family: typography.$fontFamily;
    font-size: typography.$fontSize14;
    line-height: typography.$lineHeight24;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  svg {
    path {
      fill: colors.$white;
    }
  }

  &:disabled {
    color: colors.$royalBlue20;

    svg {
      path {
        fill: colors.$royalBlue20;
      }
    }
  }

  &:hover:enabled {
    cursor: pointer;
    background: colors.$gradationPrimary110;
  }
}
