@use '../../../styles/colors';
@use '../../../styles/radius';
@use '../../../styles/lengths';
@use '../../../styles/typography';

.container {
  min-width: lengths.$fixedButtonMaxWidth;
  height: lengths.$fixedButtonHeight;
  padding: 0 16px;
  margin: 0 auto;
  font-family: typography.$fontFamily;
  font-size: typography.$fontSize14;
  line-height: typography.$lineHeight40;
  color: colors.$white;
  background: colors.$gradationPrimary100;
  border: none;
  border-radius: radius.$textFieldRadius;

  &:hover:enabled {
    cursor: pointer;
    background: colors.$gradationPrimary110;
  }
}
