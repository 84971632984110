@use '../../../styles/colors';
@use '../../../styles/lengths';

.container {
  box-sizing: border-box;
  display: flex;
  gap: lengths.$textInputGap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
  background: colors.$darkBlue100;

  .buttonSend {
    width: lengths.$liquidButtonIconOnlySize;
  }
}
