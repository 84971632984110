@use 'src/styles/colors';
@use 'src/styles/typography';
@use 'src/styles/lengths';

.container {
  display: flex;
  gap: lengths.$timesGap;
  width: 100%;
  font-family: typography.$fontFamily;
  font-size: typography.$fontSize12;
  font-weight: typography.$fontWeight400;
  line-height: typography.$lineHeight18;
  color: colors.$gray50;
}
