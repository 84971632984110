@use '../../../styles/colors';
@use '../../../styles/radius';
@use '../../../styles/lengths';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: lengths.$fixedButtonIconOnlySize;
  height: lengths.$fixedButtonIconOnlySize;
  cursor: pointer;
  background: colors.$gray10;
  border: none;
  border-radius: radius.$circle;

  &:disabled {
    cursor: default;

    svg {
      path {
        fill: colors.$gray20 !important;
      }
    }
  }

  &:hover:enabled {
    background: colors.$gray20;
  }
}
