@use '../../../styles/colors';
@use '../../../styles/radius';
@use '../../../styles/typography';
@use '../../../styles/lengths';
@use '../../../styles/shadows';

.container {
  width: fit-content;
  max-width: lengths.$commentMaxWidth;
  padding: 8px 16px;
  word-break: break-word;
  white-space: pre-wrap;
  background: colors.$gray10;
  border-radius: radius.$large;

  span {
    font-family: typography.$fontFamily;
    font-size: typography.$fontSize14;
    font-weight: typography.$fontWeight400;
    line-height: typography.$lineHeight24;
    color: colors.$gray80;

    a {
      color: colors.$royalBlue100;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .googleFormInput {
    margin-top: 6px;

    label {
      font-size: typography.$fontSize12;
      font-weight: typography.$fontWeight700;
      color: colors.$gray80;

      span {
        color: colors.$red100;
      }
    }

    input,
    textarea {
      width: 100%;
      height: 34px;
      min-height: 34px;
      padding: 4px 6px;
      font-size: typography.$fontSize12;
      line-height: 1.2;
      color: colors.$gray80;
      resize: none;
      background-color: colors.$white;
      border: 1px solid colors.$gray20;
      border-radius: 6px;
      outline: none;
    }

    textarea {
      height: 85px;
      min-height: 85px;
    }

    input:disabled,
    textarea:disabled {
      color: colors.$gray40;
      background-color: colors.$gray20;
    }

    input:-webkit-autofill,
    textarea:-webkit-autofill {
      box-shadow: 0 0 0 1000px colors.$white inset;
    }

    input:-webkit-autofill:disabled,
    textarea:-webkit-autofill:disabled {
      -webkit-text-fill-color: colors.$gray40;
      box-shadow: 0 0 0 1000px colors.$gray20 inset;
    }
  }

  .googleFormButton {
    margin-top: 18px;
    margin-bottom: 10px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 36px;
      padding: 12px 8px;
      overflow: hidden;
      font-size: typography.$fontSize12;
      font-weight: typography.$fontWeight700;
      line-height: 1.2;
      color: colors.$white;
      text-shadow: shadows.$textFieldShadow;
      background: colors.$royalBlue100;
      border-radius: 8px;
      box-shadow: shadows.$textFieldShadow;
    }
  }

  .googleFormButton button:hover {
    background: colors.$royalBlue;
  }

  .googleFormButton button:disabled {
    color: colors.$gray40;
    text-shadow: none;
    cursor: not-allowed;
    background: colors.$gray20;
    box-shadow: none;
  }
}
