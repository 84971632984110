@use '../../../styles/lengths';
@use '../../../styles/typography';
@use '../../../styles/colors';
@use '../../../styles/radius';
@use '../../../styles/shadows';

.container {
  width: 100%;
  height: 100%;

  // NOTE: font-size: 16px; にすると、フォーカス時にズームされない
  //       font-size: 14px; のデザインにできるだけ近づける
  .textarea {
    box-sizing: border-box;
    display: block;
    width: 110%;
    max-height: lengths.$textFieldMaxHeight;
    padding: 8px 16px;
    font-family: typography.$fontFamily;
    font-size: typography.$fontSize16;
    line-height: 24px;
    color: colors.$gray80;
    resize: none;
    background: colors.$white;
    border-radius: radius.$textFieldRadius;
    outline: none;
    box-shadow: shadows.$textFieldShadow;
    transform: scale(0.875) translate(-5%, 0);

    &::placeholder {
      color: colors.$gray20;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &:disabled {
      background: colors.$gray20;
      border-color: colors.$gray20;

      &::placeholder {
        color: colors.$gray40;
      }
    }

    &:focus {
      border-color: colors.$royalBlue100;
    }

    &.errored {
      border-color: colors.$red100;
    }
  }
}
