@use '../../../styles/shadows';
@use '../../../styles/radius';
@use '../../../styles/lengths';

.container {
  width: lengths.$chatContainerWidth;
  filter: drop-shadow(shadows.$messageContainerShadow);
  animation: 0.4s ease move-up;
}
@keyframes move-up {
  from {
    transform: translateY(100vh);
  }

  to {
    transform: translateY(0);
  }
}
