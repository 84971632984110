@use '../../../styles/colors';
@use '../../../styles/radius';
@use '../../../styles/typography';
@use '../../../styles/lengths';

.container {
  width: fit-content;
  max-width: lengths.$commentMaxWidth;
  padding: 8px 16px;
  word-break: break-word;
  white-space: pre-wrap;
  background: colors.$gray10;
  border-radius: radius.$large;

  span {
    font-family: typography.$fontFamily;
    font-size: typography.$fontSize14;
    font-weight: typography.$fontWeight400;
    line-height: typography.$lineHeight24;
    color: colors.$gray80;
  }
}
