@use '../../../styles/colors';
@use '../../../styles/lengths';
@use '../../../styles/typography';
@use '../../../styles/radius';

.container {
  position: relative;
  display: grid;
  grid-template: 'content' 'footer';
  grid-template-rows: 1fr auto;
  width: 100%;
  height: calc(100vh - 150px - 10px);
  min-height: lengths.$chatMessagesMinHeight;
  background: colors.$white;

  .delay {
    animation: show-delay 1s linear;
  }

  @keyframes show-delay {
    0% {
      visibility: hidden;
    }

    99% {
      visibility: hidden;
    }

    100% {
      visibility: visible;
    }
  }

  .content {
    grid-area: content;
    padding-bottom: 80px;
  }

  .footer {
    grid-area: footer;
  }

  .none {
    height: 0;
    opacity: 0;
  }

  .fadeIn {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    animation-name: fade-in-anime;
    animation-duration: 0.5s;
    animation-direction: normal;
    animation-fill-mode: forwards;
  }

  @keyframes fade-in-anime {
    from {
      opacity: 0;
      transform: translateY(72px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .fadeOut {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    animation-name: fade-out-anime;
    animation-duration: 0.5s;
    animation-direction: normal;
    animation-fill-mode: forwards;
  }

  @keyframes fade-out-anime {
    0% {
      opacity: 1;
      transform: translateY(0);
    }

    100% {
      opacity: 0;
      transform: translateY(72px);
    }
  }

  .chatMessages {
    display: flex;
    flex-direction: column;
    gap: lengths.$chatMessagesGap;
    padding: 16px;
  }

  .toast {
    padding-bottom: 12px;
  }

  .userChoices {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .userTextMessage {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .reset {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px 0;
    background-color: colors.$darkBlue100;
  }

  .txtReset {
    margin-top: 0;
    margin-bottom: 0;
    font-family: typography.$fontFamily;
    font-size: typography.$fontSize14;
    font-weight: typography.$fontWeight400;
    line-height: typography.$lineHeight24;
    color: colors.$white;
    text-align: center;
  }

  // loading animation
  .loader {
    width: fit-content;
    max-width: lengths.$commentMaxWidth;
    padding: 8px 16px;
    margin-top: 8px;
    word-break: break-word;
    white-space: pre-wrap;
    background: colors.$gray10;
    border-radius: radius.$large;
    animation: loader-fade-in 0.3s forwards;

    .loaderText {
      font-family: typography.$fontFamily;
      font-size: typography.$fontSize14;
      font-weight: typography.$fontWeight400;
      line-height: typography.$lineHeight24;
      color: colors.$gray80;

      .loaderDot {
        font-size: typography.$fontSize16;
        animation: 1.2s blink infinite;

        &:nth-child(1) {
          padding-left: 2px;
        }

        &:nth-child(2) {
          padding-right: 2px;
          padding-left: 2px;
          animation-delay: 250ms;
        }

        &:nth-child(3) {
          animation-delay: 500ms;
        }
      }
    }

    @keyframes blink {
      50% {
        opacity: 0;
      }
    }

    @keyframes loader-fade-in {
      from {
        opacity: 0;
        transform: translateY(20px);
      }

      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}
