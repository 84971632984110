@use '../../../styles/lengths';
@use '../../../styles/typography';
@use '../../../styles/colors';
@use '../../../styles/radius';
@use '../../../styles/shadows';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: lengths.$liquidButtonIconOnlySize;
  height: lengths.$liquidButtonIconOnlySize;
  cursor: pointer;
  background: colors.$gradationPrimary100;
  border: 0;
  border-radius: radius.$liquidButtonRadius;

  path {
    fill: colors.$white;
  }

  &:disabled {
    cursor: default;

    path {
      fill: colors.$royalBlue20;
    }
  }

  &:hover:enabled {
    background: colors.$gradationPrimary110;
  }
}
