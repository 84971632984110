@use '../../../styles/colors';
@use '../../../styles/radius';
@use '../../../styles/typography';
@use '../../../styles/lengths';

.container {
  display: flex;
  gap: 10px;
  align-items: center;
  height: lengths.$toastHeight;
  padding: 0 16px;
  font-family: typography.$fontFamily;
  font-size: typography.$fontSize14;
  line-height: typography.$lineHeight24;
  color: colors.$white;
  border-radius: radius.$large;

  svg {
    path {
      fill: colors.$white;
    }
  }

  &.warning {
    background: colors.$red100;
  }

  &.info {
    background: colors.$purple100;
  }
}
