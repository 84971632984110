@use '../../../styles/colors';
@use '../../../styles/radius';
@use '../../../styles/lengths';
@use '../../../styles/typography';

.container {
  display: flex;
  gap: lengths.$fixedButtonGap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: lengths.$fixedButtonHeight;
  color: colors.$white;
  background: colors.$transparent;
  border: 1px solid colors.$white;
  border-radius: radius.$fixedButtonFillRadius;

  span {
    font-family: typography.$fontFamily;
    font-size: typography.$fontSize14;
    line-height: typography.$lineHeight24;
  }

  svg {
    path {
      fill: colors.$white;
    }
  }

  &:disabled {
    color: colors.$gray50;
    border: 1px solid colors.$gray50;

    svg {
      path {
        fill: colors.$gray50;
      }
    }
  }

  &:hover:enabled {
    color: colors.$gray20;
    cursor: pointer;
    border: 1px solid colors.$gray20;

    svg {
      path {
        fill: colors.$gray20;
      }
    }
  }
}
